// Pull in core material styles
@use '@angular/material' as mat;

// Import material theming functions and definitions
@use 'dfam-app-theme' as theme;

//
@use "css-fx-layout" as fx;

// Import Roboto font
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic);

// Include the responsive API selectors from css-fx-layout
$includeResponsiveApi: true;

// If you choose to use attribute selectors:
@include fx.attribute-selectors($includeResponsiveApi);
@include fx.gap-attribute-selectors(1, 16, 1, px em, $includeResponsiveApi);
@include fx.gap-attribute-selectors-from-list(32 48, px em, $includeResponsiveApi);
@include fx.flex-size-attribute-selectors(5, 100, 5, $includeResponsiveApi);
@include fx.flex-size-attribute-selectors-from-list(50 60 70 80 90, $includeResponsiveApi);

// Reference standard color theme from dfam-app-theme
$primary: mat.get-color-from-palette(theme.$dfam-app-primary, default);

html, body, app-root {
  height: 100%;
  margin: 0px;
}

body {
  font: 14px Roboto;
  //font: 13px Verdana;  // Original Dfam Font
  //font: 14px Open Sans;
  color: #404040;
  line-height: 1.231;
}

h2 {
    margin-top: 1em;
    margin-right: 0px;
    margin-bottom: 1em;
    margin-left: 0px;
    font-size: 1.7em;
}

h3 {
    margin-top: 1em;
    margin-right: 0px;
    margin-bottom: 1em;
    margin-left: 0px;
    font-size: 1.3em;
}

// Used to hold <p></p> elements closer together
.tight {
  margin-block-end: .1em;
}

// Create the effect of an underline but extended to span
// the entire div and much closer to the text.
.underline-span {
  border-bottom: 1px solid #ddd; 
  margin-bottom: 0px;
}

img.resizing {
  max-width: 100%;
  height: auto;
}

a {
    color: $primary;
}

a:visited {
    color: $primary;
}

// RMH: 7/27/23 : This causes navigation tabs to have a thin dotted outline when clicked
//                and overall I don't like the look of the outline even on normal links.
//a:focus {
//    outline: thin dotted;
//}

footer {
    text-align: center;
    font-size: 85%;
    color: #404040;
}

// External entities get decorated with a symbol when class="ext"  
.ext
{
  background: url(/assets/images/ext.gif) no-repeat scroll right center transparent;
  padding-right: 12px;
  margin-right: 4px;
}

.bold {
    font-weight: 700;
}

div.tooltip {
    position: absolute;
    display: block;
    width: 200px;
    padding: 5px;
    font: 12px sans-serif;
    background: white;
    border-style: solid;
    border-width: 2px;
    border-color: lightsteelblue;
    border-radius: 8px;
    pointer-events: none;
}

div.outline {
    padding: 0px 0px 0px 0px;
    border-style: dotted;
    border-width: 1px;
}

// This is for the browser alpha-numeric list...could move to the .browser.scss file
.oneline {
    display: inline;
    list-style: none;
    margin: 0 auto;
}

// This is for the footer...could move to ...footer.scss
.spaced {
    letter-spacing: 3px;
}

// Annotations alignment color coding
// TODO: base these on the color theme
.hmmminus { background-color: #ffffff; }
.hmmplus { background-color: #d1e59c; }
.hmmmatch { background-color: #93b92f; }
.heatgap { background-color: #ffffff; }
.heat1 { background-color: #f4f4f4; }
.heat2 { background-color: #eaeaea; }
.heat3 { background-color: #e0e0e0; }
.heat4 { background-color: #d6d6d6; }
.heat5 { background-color: #cccccc; }
.heat6 { background-color: #c1c1c1; }
.heat7 { background-color: #b7b7b7; }
.heat8 { background-color: #adadad; }
.heat9 { background-color: #a3a3a3; }
.heatstar { background-color: #999999; }

// Table styles and overrides for Angular Material
table.mat-table {
  border-collapse: collapse;
}

th.mat-header-cell {
  background-color: #dddddd;
  font-weight: bold;
}

th.mat-header-cell, td.mat-cell {
  border: 1px solid #cccccc;
}

tr.mat-row {
  height: auto !important;
}

tr.mat-header-row {
  height: 2em !important;
}

th.mat-header-cell, td.mat-cell {
  padding: 1px 0.5em !important;
}

.help-tooltip {
  font-size: 95%;
}

// fxFlexOffset Replacements
div[flex-offset='2'] {
  margin-left: 2;
}

div[flex-offset='1em'] {
  margin-left: 1em;
}

@media screen and (min-width: 600px){
  div[offset-gt-sm='15']{
    margin-left: 15%;
    max-width: 70%;
  }

  div[offset-gt-sm='20']{
    margin-left: 20%;
    max-width: 60%;
  }

  div[offset-gt-sm='25']{
    margin-left: 25%;
    max-width: 50%;
  }

  div[offset-gt-sm='32']{
    margin-left: 32%;
    max-width: 80%;
  }

  div[data-flex-gt-sm="50"]{
    max-width: 50%;
  }

  div[data-flex-gt-sm="90"]{
    max-width: 90%;
  }
}

@media screen and (min-width: 960px){
  div[offset-gt-md='10']{
    margin-left: 10%;
    max-width: 80%;
  }
}

.indent {
  margin-left: 2%;
}