@use '@angular/material' as mat;

// Default theme 
//   Without pulling in a default theme ngx-mat-select-search
//   fails.
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

//
// Dfam Colors
//
$mat-dfam: (
        50: #eef2e5,
        100: #d5debe,
        200: #b9c892,
        300: #9db166,
        400: #88a146,
        500: #739025,
        600: #6b8821,
        700: #607d1b,
        800: #567316,
        900: #43610d,
        A100: #d5ff96,
        A200: #c1ff63,
        A400: #adff30,
        A700: #a3ff16,
        contrast:  (
            50: #ffffff,
            100: #ffffff,
            200: #ffffff,
            300: #ffffff,
            400: #ffffff,
            500: #000000,
            600: #000000,
            700: #000000,
            800: #000000,
            900: #000000,
            A100: #ffffff,
            A200: #ffffff,
            A400: #ffffff,
            A700: #ffffff
       )
    );

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dfam-app-primary: mat.define-palette($mat-dfam, 500);
$dfam-app-accent:  mat.define-palette($mat-dfam, 600, A100, A400);

// The warn palette is optional (defaults to red).
$dfam-app-warn:    mat.define-palette(mat.$red-palette);

$display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em);

$dfam-typography-config: mat.define-typography-config(
  $font-family: 'Roboto',
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $headline-6: mat.define-typography-level(20px, 32px, 400),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $body-2: mat.define-typography-level(14px, 20px, 400),
  // Changed from 16px to 14px, and line height from 28 to 20 and finally weight from 400 to 600
  $subtitle-2: mat.define-typography-level(14px, 20px, 600),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500)
);

// Create the theme object (a Sass map containing all of the palettes).
$dfam-app-theme: mat.define-light-theme((
    color: (
        primary: $dfam-app-primary, 
        accent: $dfam-app-accent, 
        warn: $dfam-app-warn
        ),
    typography: $dfam-typography-config,
    density: 0,
));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($dfam-app-theme);
// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
@include mat.all-component-themes($dfam-app-theme);


// RMH: 7/27/23 : This was necessary to set the tab navigation font to match the
//                theme colors.  This is perhaps not the best way to do this.
.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
//  --mdc-tab-indicator-active-indicator-color: $dfam-app-primary;
  --mat-tab-header-inactive-label-text-color: $dfam-app-primary;
//  --mat-tab-header-active-label-text-color: $dfam-app-accent;
//  --mat-tab-header-active-ripple-color: $dfam-app-accent;
  --mat-tab-header-inactive-focus-label-text-color: $dfam-app-accent;
  --mat-tab-header-inactive-hover-label-text-color: $dfam-app-accent;
//  --mat-tab-header-active-focus-label-text-color: $dfam-app-accent;
//  --mat-tab-header-active-hover-label-text-color: $dfam-app-accent;
//  --mat-tab-header-active-focus-indicator-color: $dfam-app-accent;
//  --mat-tab-header-active-hover-indicator-color: $dfam-app-accent;
//  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
//  --mat-tab-header-pagination-icon-color: #000;
//  --mat-tab-header-inactive-ripple-color: $dfam-app-primary;
}


